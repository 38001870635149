import React from "react";
import {Link} from 'react-scroll';

class Header extends React.Component {
    render(){
        return (
            <header id="header" className="sticky">
                    <div>
                        <h2 id="site-name">Dylan Bednar</h2>
                    </div>
                    <div>
                        <ul id="header-links">
                            <li className="header-link"><Link to="project-area" spy={true} smooth={true}>Projects</Link></li>
                            <li className="header-link"><a href="images/Resume-Single.pdf" download="DylanBednarResume">Resume</a><i id="download" className="fa-solid fa-file-arrow-down" /></li>
                        </ul>
                    </div>
                    <script>
                        console.log();
                    </script>
            </header>
        );
    }
}
export default Header;