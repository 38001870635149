import React from "react";
import Profile from "./Profile";

class Resume extends React.Component {
    render() {
        return (
           <div>
               <img id="resume" src="images/resume.PNG"></img>
           </div> 
        )
    }
}

export default Resume;